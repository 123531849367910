.timelineContainer > .horizontal,
.timelineContainerNoItems > .horizontal {
  position: relative;
  z-index: 1;
  margin-top: -38px !important;
}

.horizontal.timeline-main-wrapper > .horizontal {
  width: 100%;
}

.horizontal.timeline-main-wrapper > .horizontal .timeline-item-title {
  font-weight: normal;
}

.horizontal.timeline-main-wrapper > .horizontal .timeline-item-title.active {
  background: transparent !important;
  color: #000 !important;
  /*font-weight: bold; */
}

.horizontal.timeline-main-wrapper > .horizontal .timeline-circle.horizontal {
  width: 25px;
  height: 25px;
}

.horizontal.timeline-main-wrapper > .horizontal .timeline-circle.horizontal.active {
  /* background-color: rgb(24, 144, 255); */
  background-color: #ffffff;
}

.horizontal.timeline-main-wrapper > .horizontal .timeline-circle.horizontal.active:after {
  width: 20px;
  height: 20px;
}

[class*='TimelineControlContainer'] {
  justify-content: right !important;
}

[class*='TimelineControlContainer'] button {
  background: #1890ff !important;
}

.timelineContainer .timeline-controls,
.timelineContainerNoItems .timeline-controls {
  background-color: #fafafb !important;
  margin-top: 2px;
}

.timelineContainerNoItems .timeline-main-wrapper {
  display: none;
}


.noTextOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}



.gm-style {
  font-family: inherit !important;
  text-decoration: none;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(-50%,-100%,0);
          transform: translate3d(-50%,-100%,0);
  background-color: #000;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
  border:none!important;}

.gm-style .gm-style-iw-c.focus-visible {
  border:none!important;
  outline: none !important;
}

.gm-style .gm-style-iw-c:focus-visible {
  border:none!important;
  outline: none !important;
}


.gm-style .gm-style-iw-tc::after {
  background: #000;
  -webkit-clip-path: polygon(0% 0%,50% 100%,100% 0%);
          clip-path: polygon(0% 0%,50% 100%,100% 0%);
  content: '';
  height: 12px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}

.gm-style .gm-style-iw-c button {
  display: none !important;
}

.gm-style-iw-d {
  background: #000;
  overflow: auto !important;
  border:none!important;
}

.gm-style-iw {
  overflow:hidden!important;
}



